import React, { useEffect, useState } from 'react'

import {
  CodeBracketIcon,
  CogIcon,
  MapIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline'
import { captureEvent, captureException } from '@sentry/browser'
import type {
  CreateLearnerActivityMutation,
  CreateLearnerActivityMutationVariables,
  CreateLearnerQuestionMutation,
  CreateLearnerQuestionMutationVariables,
  CreateLearnerTaskMutation,
  CreateLearnerTaskMutationVariables,
  DeleteLearnerActivityMutation,
  DeleteLearnerActivityMutationVariables,
  DeleteLearnerTaskMutation,
  DeleteLearnerTaskMutationVariables,
  LearnerStatus,
  RankLearnerItemsMutation,
  RankLearnerItemsMutationVariables,
  UpdateLearnerActivityInput,
  UpdateLearnerActivityMutation,
  UpdateLearnerActivityMutationVariables,
  UpdateLearnerCourseInput,
  UpdateLearnerCourseMutation,
  UpdateLearnerCourseMutationVariables,
  UpdateLearnerTaskInput,
  UpdateLearnerTaskMutation,
  UpdateLearnerTaskMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Empty from 'src/components/Empty/Empty'
import HelperArrow from 'src/components/HelperArrow/HelperArrow'
import { LinkedResourceType } from 'src/components/HubDash/HubDashLayoutListCell'
import Button from 'src/components/Library/Button/Button'
import LinkedContentBadge, {
  type LinkedResourceRow,
} from 'src/components/Library/LinkedContentBadge/LinkedContentBadge'
import PageHeader from 'src/components/PageHeader/PageHeader'
import ChapterDragAndDrop from 'src/components/Settings/LearnerCourse/EditLearnerCourse/ChapterDragAndDrop/ChapterDragAndDrop'
import CourseDetails from 'src/components/Settings/LearnerCourse/EditLearnerCourse/CourseDetails/CourseDetails'
import LessonSelector from 'src/components/Settings/LearnerCourse/EditLearnerCourse/LessonSelector/LessonSelector'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import {
  CREATE_ACTIVITY_MUTATION,
  DELETE_ACTIVITY_MUTATION,
  UPDATE_ACTIVITY_MUTATION,
} from 'src/lib/queries/learner/learnerActivity'
import { UPDATE_COURSE_MUTATION } from 'src/lib/queries/learner/learnerCourse'
import { CREATE_QUESTION_MUTATION } from 'src/lib/queries/learner/learnerQuestion'
import {
  CREATE_TASK_MUTATION,
  DELETE_TASK_MUTATION,
  UPDATE_TASK_MUTATION,
} from 'src/lib/queries/learner/learnerTask'
import RANK_ITEM_MUTATION from 'src/lib/queries/learner/rank'
import { LessonType, LessonViews } from 'src/types/enums'
import { nextRank } from 'src/Util'
import { useAuth } from 'web/src/Providers'

import ActivityDetails from '../../LearnerCategory/Categories/ActivityDetails/ActivityDetails'
import EditLearnerTask from '../../LearnerTask/EditLearnerTask/EditLearnerTask'
import {
  LearnerCategories,
  LearnerCourse,
  LearnerCourseRefetch,
} from '../EditLearnerCourseCell'

interface Props {
  course: LearnerCourse
  categories: LearnerCategories
  refetch: LearnerCourseRefetch
}

const EditLearnerCourse: React.FC<Props> = ({
  course,
  categories,
  refetch,
}) => {
  const { currentUser } = useAuth()

  const clientId = currentUser.parentData.id

  const [learnerActivities, setLearnerActivities] = useState(
    course.learnerActivities,
  )
  useEffect(() => {
    setLearnerActivities(course.learnerActivities)
  }, [course])
  const [selectedTaskId, setSelectedTaskId] = useState<number>(null)
  const [orderedLearnerActivities, setOrderedLearnerActivities] = useState<
    any[] | undefined
  >()
  const [learnerTasks, setLearnerTasks] = useState<undefined | any[]>()
  const [selectedActivity, setSelectedActivity] = useState<undefined | any>()
  const [isUpdating, setIsUpdating] = useState(false)
  const [taskType, setTaskType] = useState<LessonType>()
  const [view, setView] = useState<LessonViews>(LessonViews.none)
  const courseId = course.id
  const confirm = useConfirm()

  const [linkedWorkflowsRows, setLinkedWorkflowsRows] = useState<
    LinkedResourceRow[]
  >([])

  const lessonOptions = [
    {
      id: 'content-type-lesson-selector',
      title: 'Content',
      description: 'Add video or text content to your chapter',
      icon: (
        <svg
          className="m-auto mb-4 h-20 w-20 stroke-1 text-gray-300"
          viewBox="0 0 85 85"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31 20L40.1056 15.4472C41.4354 14.7823 43 15.7493 43 17.2361V30.7639C43 32.2507 41.4354 33.2177 40.1056 32.5528L31 28M11 36H27C29.2091 36 31 34.2091 31 32V16C31 13.7909 29.2091 12 27 12H11C8.79086 12 7 13.7909 7 16V32C7 34.2091 8.79086 36 11 36Z"
            stroke="#D1D5DB"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55 61H67M55 69H67M71 79H51C48.7909 79 47 77.2091 47 75V47C47 44.7909 48.7909 43 51 43H62.1716C62.702 43 63.2107 43.2107 63.5858 43.5858L74.4142 54.4142C74.7893 54.7893 75 55.298 75 55.8284V75C75 77.2091 73.2091 79 71 79Z"
            stroke="#D1D5DB"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="73"
            y1="11"
            x2="11"
            y2="73"
            stroke="#D1D5DB"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
      ),
      type: LessonType.video,
    },
    {
      id: 'quiz-type-lesson-selector',
      title: 'Quiz',
      description: 'Add a multiple choice quiz to your chapter',
      icon: (
        <PuzzlePieceIcon className="m-auto mb-4 h-20 w-20 stroke-1 text-gray-300" />
      ),
      type: LessonType.quiz,
    },
    {
      id: 'processMap-type-lesson-selector',
      title: 'Process Map',
      description: 'Add a process map to your chapter',
      icon: (
        <MapIcon className="m-auto mb-4 h-20 w-20 stroke-1 text-gray-300" />
      ),
      type: LessonType.processMap,
    },
    {
      id: 'embed-type-lesson-selector',
      title: 'Embed',
      description: 'Add embed content to your chapter',
      icon: (
        <CodeBracketIcon className="m-auto mb-4 h-20 w-20 stroke-1 text-gray-300" />
      ),
      type: LessonType.embed,
    },
  ]

  useEffect(() => {
    if (selectedActivity) {
      setLearnerTasks(selectedActivity.learnerTasks)
    }
  }, [selectedActivity])

  useEffect(() => {
    if (orderedLearnerActivities) {
      // setIsUpdating to true as a stop to disallow further changes until after the mutation completes
      setIsUpdating(true)
      const reorderedItemsInput = orderedLearnerActivities.map(
        (item, index) => ({
          id: item.id,
          rank: index + 1,
        }),
      )
      rankLearnerItems({
        variables: {
          input: { itemType: 'Activity', items: reorderedItemsInput },
        },
      })
    }
  }, [orderedLearnerActivities])

  const [updateLearnerCourse] = useMutation<
    UpdateLearnerCourseMutation,
    UpdateLearnerCourseMutationVariables
  >(UPDATE_COURSE_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Course updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [deleteLearnerActivity] = useMutation<
    DeleteLearnerActivityMutation,
    DeleteLearnerActivityMutationVariables
  >(DELETE_ACTIVITY_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Chapter deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [rankLearnerItems] = useMutation<
    RankLearnerItemsMutation,
    RankLearnerItemsMutationVariables
  >(RANK_ITEM_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Course updated')
      setIsUpdating(false)
      setOrderedLearnerActivities(null)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [createLearnerQuestion] = useMutation<
    CreateLearnerQuestionMutation,
    CreateLearnerQuestionMutationVariables
  >(CREATE_QUESTION_MUTATION, {
    onCompleted: () => {
      toast.success('Question created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [createLearnerActivity] = useMutation<
    CreateLearnerActivityMutation,
    CreateLearnerActivityMutationVariables
  >(CREATE_ACTIVITY_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Chapter created')
    },
    onError: (error) => {
      toast.error('Could not save chapter, please refresh and try again')
      captureEvent({
        message: 'saveNewActivity: Could not save chapter',
        extra: { error },
      })
    },
  })

  const saveNewActivity = async (input: { name: string }) => {
    const castInput = Object.assign(input, {
      clientId: clientId,
      rank: nextRank(learnerActivities),
      status: 'DRAFT' as LearnerStatus,
      learnerCourseId: course.id,
    })

    await createLearnerActivity({ variables: { input: castInput } })
  }

  const onDeleteActivity = (id: number, name: string, mainView = true) => {
    confirm({
      title: `Delete ${name}?`,
      description: 'Are you sure you want to delete this chapter?',
    }).then((isConfirmed) => {
      if (!isConfirmed) return
      deleteLearnerActivity({ variables: { id } })
      if (mainView) {
        setView(LessonViews.none)
        setTaskType(LessonType.none)
      }
    })
  }

  const [updateLearnerActivity] = useMutation<
    UpdateLearnerActivityMutation,
    UpdateLearnerActivityMutationVariables
  >(UPDATE_ACTIVITY_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Chapter updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const [updateLearnerTask] = useMutation<
    UpdateLearnerTaskMutation,
    UpdateLearnerTaskMutationVariables
  >(UPDATE_TASK_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Task updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const allTasksDraft = (task) => {
    return task.status === 'DRAFT'
  }

  //TODO this is a duplicate, can this be avoided by class extends
  const toggleLearnerItemPublished = (learnerItem) => {
    const id = learnerItem.id
    const input = {
      status: learnerItem.status === 'PUBLISHED' ? 'DRAFT' : 'PUBLISHED',
    }

    switch (learnerItem.__typename) {
      case 'LearnerTask':
        updateLearnerTask({
          variables: { id, input: input as UpdateLearnerTaskInput },
        }).then(() => {
          if (learnerItem.status === 'PUBLISHED') {
            const modifiedTasks = selectedActivity.learnerTasks.filter(
              (task) => task.id !== id,
            )
            if (modifiedTasks.every(allTasksDraft)) {
              updateLearnerActivity({
                variables: {
                  id: selectedActivity.id,
                  input: {
                    status: 'DRAFT',
                  },
                },
              })
            }
          } else if (learnerItem.status !== 'PUBLISHED') {
            updateLearnerActivity({
              variables: {
                id: selectedActivity.id,
                input: {
                  status: 'PUBLISHED',
                },
              },
            })
          }
        })
        break
      case 'LearnerCourse':
        updateLearnerCourse({
          variables: { id, input: input as UpdateLearnerCourseInput },
        })
        break
      case 'LearnerActivity':
        updateLearnerActivity({
          variables: {
            id,
            input: input as UpdateLearnerActivityInput,
          },
        })
        break
      default:
        captureException(
          new Error('An error occurred type is undefined in switch case'),
        )
    }
  }

  const onSelectedChapter = (chapter: any) => {
    setSelectedActivity(chapter)
    setView(LessonViews.chapter)
  }

  const updateActivityName = (id, newName) => {
    const input = {
      name: newName,
    }
    updateLearnerActivity({ variables: { id, input: input } })
  }

  const [createLearnerTask] = useMutation<
    CreateLearnerTaskMutation,
    CreateLearnerTaskMutationVariables
  >(CREATE_TASK_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Task created')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const saveNewTask = async (input) => {
    const castInput = Object.assign(input, {
      clientId: clientId,
      rank: nextRank(learnerTasks),
      status: 'DRAFT',
      learnerActivityId: selectedActivity.id,
    })

    const response = await createLearnerTask({
      variables: { input: castInput },
    })
    setSelectedTaskId(response.data.createLearnerTask.id)
    return response.data.createLearnerTask
  }

  const newBlankLesson = async (type) => {
    const newTask = await saveNewTask({
      name: 'NEW LESSON',
    })

    if (type === LessonType.quiz) {
      createLearnerQuestion({
        variables: {
          input: {
            question: 'New Question',
            rank: 1,
            choices: [
              {
                text: 'New Option',
                rank: 1,
                isAnswer: false,
              },
            ],
            clientId,
            required: false,
            learnerTaskId: newTask.id,
          },
        },
      })
    }
  }

  const onSaveCourse = (id, input) => {
    const castInput = Object.assign(input, {
      learnerCategoryId: course?.learnerCategoryId,
    })
    updateLearnerCourse({ variables: { id, input: castInput } })
  }

  const onCategoryChange = (id, categoryId) => {
    const castInput = { learnerCategoryId: categoryId }
    updateLearnerCourse({ variables: { id, input: castInput } })
  }

  const updateCourseName = (id, newName) => {
    const input = {
      name: newName.slice(0, 96),
    }
    updateLearnerCourse({ variables: { id, input: input } })
  }

  const handleSettings = () => {
    setView(LessonViews.settings)
  }

  const [border, setBorder] = useState('border border-gray-300')
  useEffect(() => {
    if (view === LessonViews.settings) {
      setBorder('border-2 border-indigo-600')
    } else setBorder('border border-gray-300')
  }, [view])

  const onNewLesson = (chapter: any) => {
    setSelectedTaskId(null)
    setView(LessonViews.new)
    setSelectedActivity(chapter)
  }

  const onSelectedLesson = (lesson: any, chapter: any) => {
    setSelectedTaskId(lesson.id)
    if (chapter) {
      setSelectedActivity(chapter)
    }

    if (lesson.video && lesson.text) {
      setTaskType(LessonType.video)
      setView(LessonViews.edit)
    } else if (lesson.video) {
      setTaskType(LessonType.video)
      setView(LessonViews.edit)
    } else if (lesson.quiz && lesson.quiz.length > 0) {
      if (lesson.quiz.length > 0) {
        setTaskType(LessonType.quiz)
        setView(LessonViews.edit)
      }
    } else if (lesson.processMapId) {
      setTaskType(LessonType.processMap)
      setView(LessonViews.edit)
    } else if (lesson.embedURL && lesson.text) {
      setTaskType(LessonType.embed)
      setView(LessonViews.edit)
    } else if (lesson.embedURL) {
      setTaskType(LessonType.embed)
      setView(LessonViews.edit)
    } else if (lesson.text) {
      setView(LessonViews.edit)
      setTaskType(LessonType.text)
    } else {
      setTaskType(LessonType.unknown)
      setView(LessonViews.new)
    }
  }
  const handleForm = (type: LessonType) => {
    if (!selectedTaskId) {
      newBlankLesson(type)
    }
    setTaskType(type)
    setView(LessonViews.edit)
  }

  const [deleteLearnerTask] = useMutation<
    DeleteLearnerTaskMutation,
    DeleteLearnerTaskMutationVariables
  >(DELETE_TASK_MUTATION, {
    onCompleted: async () => {
      await refetch()
      toast.success('Task deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    // awaitRefetchQueries: true,
  })

  const onDeleteTask = (id, name) => {
    confirm({
      title: `Delete ${name}?`,
      description: 'Are you sure you want to delete this lesson?',
    }).then((isConfirmed) => {
      if (!isConfirmed) return
      deleteLearnerTask({ variables: { id } })
      const firstTaskId = selectedActivity.learnerTasks[0]?.id
      const selectedTaskId =
        id === firstTaskId ? selectedActivity.learnerTasks[1]?.id : firstTaskId

      if (typeof selectedTaskId === 'undefined') {
        updateLearnerActivity({
          variables: {
            id: selectedActivity.id,
            input: {
              status: 'DRAFT',
            },
          },
        })
      } else {
        setSelectedTaskId(selectedTaskId)
      }
    })
  }

  return (
    <>
      <PageHeader title={'Edit ' + course.name} backNavigation />

      <div className="sl-height flex w-full align-middle">
        <style>
          {`
          .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              border-bottom: none;
          }
          .lessonText .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              border-bottom: none;
          }

          .courseText .e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 28px;
              border-bottom: none;
          }
        `}
        </style>
        <div className="flex h-full flex-col bg-gray-50">
          <header className="p-4">
            <div className="max-h[155px] max-w-[305px] rounded-lg">
              {course.heroStorageObject?.downloadUrl ? (
                <img
                  src={course.heroStorageObject?.downloadUrl}
                  alt="Course hero"
                  className="h-[160px] w-[305px] rounded-lg bg-gray-200 object-cover object-center"
                />
              ) : (
                <Empty className="rounded-md bg-gray-200" image />
              )}
            </div>
            <div className="border-b border-gray-200 py-4">
              <span className="text-[11px] uppercase tracking-wider text-gray-400">
                Course
              </span>
              <h3 className="text-base font-medium">{course.name}</h3>
            </div>
          </header>
          <main className="flex-grow overflow-y-auto p-2 pt-0">
            <div className="justify-items-center bg-gray-50">
              <div className="m-auto w-[304px]">
                <div
                  className={`m-auto mb-4 w-[304px] bg-white ${border} rounded-md`}
                >
                  <Button
                    className="capitalize text-gray-900"
                    buttonDataTestId="edit-learner-course-settings-button"
                    onClick={handleSettings}
                    size="small"
                    fullWidth
                    disableRipple
                    variant="text"
                    startIcon={<CogIcon className="color h-5 w-5" />}
                  >
                    Settings
                  </Button>
                </div>
                <ChapterDragAndDrop
                  chapters={learnerActivities}
                  setChapters={setLearnerActivities}
                  onNewLesson={onNewLesson}
                  onSelectedLesson={onSelectedLesson}
                  onSelectedChapter={onSelectedChapter}
                  isUpdating={isUpdating}
                  setOrderedChapters={setOrderedLearnerActivities}
                  setSelectedActivity={setSelectedActivity}
                  selectedActivity={selectedActivity}
                  refetch={refetch}
                  view={view}
                />
                {learnerActivities.length < 2 &&
                  learnerActivities[0]?.learnerTasks?.length === 0 && (
                    <HelperArrow className="mt-1/2 w-1/2" direction="up">
                      <p className="py-2.5 text-base font-medium text-gray-600">
                        Add a new lesson
                      </p>
                    </HelperArrow>
                  )}
              </div>
            </div>
          </main>
          <footer className="px-4">
            {learnerActivities.length < 1 && (
              <HelperArrow className="mt-1/2 w-1/2" direction="down">
                <p className="py-2.5 text-base font-medium text-gray-600">
                  Add a new chapter to get started
                </p>
              </HelperArrow>
            )}
            <div className="mb-4 flex justify-center">
              <Button
                buttonDataTestId="edit-learner-course-add-new-chapter-button"
                onClick={() => saveNewActivity({ name: 'NEW CHAPTER' })}
              >
                Add A New Chapter +
              </Button>
            </div>
          </footer>
        </div>
        <div className="relative min-h-full w-screen overflow-y-scroll bg-white align-middle drop-shadow-md">
          <div className="flex h-auto min-h-full items-center justify-center">
            <div className="w-full max-w-[900px] px-9 py-4">
              {(() => {
                switch (view) {
                  case LessonViews.settings:
                    return (
                      <CourseDetails
                        course={course}
                        onDeleteActivity={onDeleteActivity}
                        activity={selectedActivity}
                        chapters={learnerActivities}
                        onCategoryChange={onCategoryChange}
                        onSave={onSaveCourse}
                        onSelectedChapter={onSelectedChapter}
                        updateCourseName={updateCourseName}
                        setChapters={setLearnerActivities}
                        togglePublished={toggleLearnerItemPublished}
                        categories={categories}
                        setOrderedChapters={setOrderedLearnerActivities}
                        refetch={refetch}
                        linkedWorkflowsRows={linkedWorkflowsRows}
                      />
                    )
                  case LessonViews.chapter:
                    return (
                      <ActivityDetails
                        activity={selectedActivity}
                        onDeleteActivity={onDeleteActivity}
                        onDeleteTask={onDeleteTask}
                        togglePublished={toggleLearnerItemPublished}
                        updateActivityName={updateActivityName}
                        onSelectedLesson={onSelectedLesson}
                        refetch={refetch}
                      />
                    )
                  case LessonViews.new:
                    return (
                      <>
                        <Empty title="Select Lesson Type" />
                        <LessonSelector
                          handleSelect={handleForm}
                          lessonOptions={lessonOptions}
                        />
                      </>
                    )
                  case LessonViews.edit:
                    return (
                      <EditLearnerTask
                        refetch={refetch}
                        course={course}
                        selectedActivityId={selectedActivity.id}
                        taskId={selectedTaskId}
                        learnerCourseId={courseId}
                        taskType={taskType}
                        onDeleteTask={onDeleteTask}
                        togglePublished={toggleLearnerItemPublished}
                        setSelectedActivity={setSelectedActivity}
                        selectedActivity={selectedActivity}
                      />
                    )
                  default:
                    return (
                      <Empty
                        learnerCourse
                        title="Let's build your course!"
                        description="Start by adding a new chapter. Followed by adding a new lesson. Select the lesson type and then add your content. Make sure to publish your course when you are done."
                      />
                    )
                }
              })()}
            </div>
          </div>
          <LinkedContentBadge
            resourceId={course?.id}
            resourceType={LinkedResourceType.LEARNER_COURSE}
            returnLinkedContent={setLinkedWorkflowsRows}
          />
        </div>
      </div>
    </>
  )
}

export default EditLearnerCourse
